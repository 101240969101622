export const getImg = (imgFileName: string): string => {
  return require(`./images/${imgFileName}`);
};

const imgs = {
  WillGet: getImg('bg-willget.png'),
  LineCenter: getImg('linecenter.png'),
  SelectBg: getImg('select-bg.png'),
  SearchBg: getImg('search-bg.png'),
  LogoHeader: getImg('logo-header.png'),
  Logo: getImg('lg-logo.png'),
  BubbleMessage: getImg('bubble-message.png'),
  TypingImage: getImg('typing.gif'),
  IClock: getImg('i_clock.png'),
  ProfileSmall: getImg('bg_profile_small.png'),
  ProfileLarger: getImg('bg_profile_large.png'),
  PlaceHolderImage: getImg('place-holder.png'),
  DemoContent: getImg('democontent.png'),
  ImgIcon: getImg('icon_img.png'),
  DropDownImg: getImg('bg-dropimg.png'),
  IconGoogle: getImg('icon_google.png'),
  IconKeycloak: getImg('icon_keycloak.png'),
  SlideBg: getImg('slide-bg1.png'),
  SlideBg2: getImg('hotnewbg.png'),
  ForgotPasswordGB: getImg('forgot_password_bg.png'),
  ResetPasswordBg: getImg('reset_password_bg.png'),
  PortalDefault: getImg('portalDefault.png'),
  ImgGPT: getImg('avt-gpt.png')
};

const icons = {
  logoSrc: getImg('logo.png'),
  Icon0Active: getImg('icon0.png'),
  Icon0: getImg('icon0-active.png'),
  Icon2: getImg('icon2.png'),
  Icon2Active: getImg('icon2-active.png'),
  Icon3: getImg('icon3.png'),
  Icon3Active: getImg('icon3-active.png'),
  Icon5: getImg('icon5.png'),
  Icon7: getImg('icon7.png'),
  Icon7Active: getImg('icon7Active.png'),
  Icon5Active: getImg('icon5-active.png'),
  Bell: getImg('bell.png'),
  Bell2: getImg('bell2.png'),
  IconAlert: getImg('icon-erorr.png'),
  IconForPoint: getImg('icon-point.png'),
  IconUsePoint: getImg('icon-use-point.png'),
  Iacc: getImg('icon_key.png'),
  Ipost: getImg('icon_write.png'),
  Ipoint: getImg('icon_points.png'),
  CheckedNew: getImg('icon-checked-new.png'),
  IconPlus: getImg('icon-plus.png'),
  IconNextWhite: getImg('icon-next-white.png'),
  IconNextBlack: getImg('icon-next-black.png'),
  IconPreviousWhite: getImg('icon-previous-white.png'),
  IconPreviousBlack: getImg('icon-previous-black.png'),
  IconChecked: getImg('icon_checked.png'),
  IconEdit: getImg('i_edit.png'),
  IconOpenRight: getImg('openright.png'),
  IconArrowRight: getImg('arrowright.png'),
  WillGet: getImg('bg-willget.png'),
  LineCenter: getImg('linecenter.png'),
  SelectBg: getImg('select-bg.png'),
  SearchBg: getImg('search-bg.png'),
  Logo: getImg('lg-logo.png'),
  BubbleMessage: getImg('bubble-message.png'),
  TypingImage: getImg('typing.gif'),
  PlaceHolderImage: getImg('place-holder.png'),
  DemoContent: getImg('democontent.png'),
  ImgIcon: getImg('icon_img.png'),
  HeartFull: getImg('icon-heart-full.png'),
  IconMessage: getImg('icon-message.png'),
  IconSee: getImg('icon-eye.png'),
  IconBookMark: getImg('icon-bookmark.png'),
  IconSeeBookmark: getImg('icon-bookmark.png'),
  IconUnHeart: getImg('icon-unlike.png'),
  IconFilter: getImg('icon-filter.png'),
  IconFilterOuline: getImg('filter-outline.png'),
  IconFilterSolid: getImg('filter-solid.png'),
  GifIcon: getImg('icon_gif.png'),
  GifIconNone: getImg('icon_gif2.png'),
  GifIconStatus: getImg('icon-gif-status.png'),
  IconHeart: getImg('icon_heart.png'),
  CloseImg: getImg('icon_close_img.png'),
  IEdit: getImg('i_edit_2.png'),
  IconClock: getImg('icon_clock.png'),
  IconLocation: getImg('icon_location.png'),
  IconOrganizer: getImg('icon_organizer.png'),
  IconBookmarkSave: getImg('icon-save-bookmark.png'),
  IconPlusPoint: getImg('icon_plus_point.png'),
  IconDone: getImg('icon_done_green.png'),
  IClose: getImg('close.png'),
  DropDownImg: getImg('bg-dropimg.png'),
  Hash: getImg('hash.svg'),
  AtSign: getImg('at-sign.svg'),
  IPlusIcon: getImg('plusIcon.svg'),
  IPlusIconWhite: getImg('plusIconWhite.svg'),
  IFeelingIcon: getImg('feelingIcon.svg'),
  IPollIcon: getImg('poll-icon.svg'),
  IIconStatusBackground: getImg('status-background-icon.png'),
  ICloseButtonLight: getImg('icon-close-light.png'),
  ICloseButton: getImg('icon-close.png'),
  IIconCopy: getImg('icon_copy.png'),
  IIconPlay: getImg('icon_play.png'),
  IconCopy: getImg('i_copy.png'),
  IconTrash: getImg('i_trash.png'),
  IconMore: getImg('icon-more.png'),
  Top1st: getImg('top/top1st.png'),
  Top2nd: getImg('top/top2nd.png'),
  Top3rd: getImg('top/top3rd.png'),
  StarEmpty: getImg('star-empty.svg'),
  StarFill: getImg('star-fill.svg'),
  PaperPlane: getImg('paper-plane.svg'),
  UserImage: getImg('userImage.png')
};

export { icons, imgs };
